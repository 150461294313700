.star_wrapper {
    position: relative;
    background: none;
    border: none;

}


.star_text {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    line-height:1;
    pointer-events: none;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:1em;
    font-weight:800;
    margin:0;
    padding-bottom: 15%;
}
.camera_modal_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    margin: 0.5em;
    width: 100%;
    height: 100%;
}

.camera_modal_wrap_btns {
    display: flex;
    align-items: center;
    gap: 2em;
    margin-bottom: 0.5em;
}
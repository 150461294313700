.MemoryCards_wrap {
    touch-action: none;
    overflow: hidden;
    position: relative;
}

.CardPlaceHolder {
    position: absolute;
    padding: 0.15rem;
}


.CardInner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    border-radius: 5px;
}

.CardText {
    font-size: 4rem;
    color: white;
    font-weight: 800;
}

.CardImg {
    width: 100%;
    height: 100%;
}
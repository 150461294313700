.modalWrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px !important;
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    height: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.modal_content {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em;
    box-sizing: unset;
}

#camera.modal_content {
    background-color: white;
}

#folder.modal_content {
    background-color: white;
}

#address.modal_content {
    background-image: url('../../assets/background/address_modal.svg');
}

#login.modal_content {
    background-image: url('../../assets/background/login_modal.svg');
}

.close_modal_wrap {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    margin-top: 1em;
}

.close_modal_img {
    cursor: pointer;
}
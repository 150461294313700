.app_menu_btn {
    background: none;
    border: none;
    cursor: pointer;
}

.app_menu_btn_svg {
    width: 2.5em;
}

.app_menu_wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.app_menu_top {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    background: #5DB9F6;
}

.appmenu_close {
    cursor: pointer;
}

.app_menu_content_top {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
}

#top.app_menu_button_wraper{
    margin: 0px;
    background: #5DB9F6;
    padding-top: 1em;
    padding-bottom: 1em;
}


.app_menu_button_wraper {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 3em;
}

.app_menu_btn_text {
    color: white;
    font-weight: 600;
    font-size: 1.25rem;
    cursor: pointer;
    background: none;
    border: none;
    text-align: start;
}

.app_menu_to_home_text {
    color: #345487;
    font-weight: 700;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: underline;
}

.app_menu_btn_text:disabled {
    cursor: auto;
}

.app_menu_content_bottom {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 4em;
}

#noicon.app_menu_btn_text {
    margin-right: 3em;
    font-weight: 300;
}

/* floating drawwing */

.float {
    animation: float 10s linear infinite;
}
@keyframes float {
    0%,100%{}
    30%{transform: translate(5px,10px) rotate(4deg);}
    70%{transform: translate(10px,-10px) rotate(-4deg);}
}
.float-delay {
    animation-delay: 5s;
}

.crMegaphone {
    width: 4em;
    position: absolute;
    top: 10%;
    left: 0%;
    background: white;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.14);
    border-radius: 0px 22px 22px 0px;
    cursor: pointer;
}

@keyframes btnScale {
    0%,100%{}
    50%{transform: scale(.95);}
}

.crMegaphone:hover {
    animation: btnScale .5s forwards;
}

.crRabbit {
    width: 6em;
    position: absolute;
    top: 72%;
    right: 5%;
}

.crPen {
    width: 4em;
    position: absolute;
    top: 50%;
    right: 6%;
}

.crApple {
    width: 4em;
    position: absolute;
    top: 40%;
    right: 7%;
}

.crBaloon {
    width: 7em;
    position: absolute;
    top: 20%;
    left: 4%;
}

.crPaint {
    width: 4em;
    position: absolute;
    top: 84%;
    left: 6%;
}

.crTube {
    width: 3em;
    position: absolute;
    top: 50%;
    left: 3%;
}
.fim_title {
    margin: 0;
    align-self: center;
    font-weight: 800;
    font-size: 1.5rem;
    color: #0761A6;
}

.my-folders{
    width:100%;
    max-width: 900px;
    padding:2rem;
    padding-top:6.5rem;
    border-radius:28px;
    background: #40A9EF url('src/assets/folder/folders.png') repeat left;
    margin-top: 2em;
    margin-bottom: 2em;
}
.my-folder-corner{
    background: #40A9EF;
    width:170px;
    border-radius:28px 28px 0 0;
    height:60px;
    position:absolute;
    top:-30px;
    left:0;
}
.my-folder-corner:before{
    content: "";
    display:block;
    height:100%;
    border-bottom:50px solid #40A9EF;
    border-right:50px solid transparent;
    position:absolute;
    right: -43px;
    bottom:0;
}
.cloudy{
    width:70px;
    position:absolute;
    left:1rem;
    top:.3rem;
    z-index: 1;
}
.rainbow{
    width:80px;
    position:absolute;
    left:8rem;
    top:1rem; 
    z-index: 1;
}
.light{
    width:60px;
    position:absolute;
    right:1rem;
    top:.8rem; 
}
.folders-wrap{
    gap:1.4rem;
    row-gap: 3rem;
}
.folder-group{
    width: calc(100% / 4 - 1.5rem);
}
.pocket{
    border-radius: 0px 0px 20px 20px;
    background: #BEEDFB;
    height:100px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: .3s;
    cursor: pointer;

}
.pocket:before{
    content: "";
    display:block;
    border-radius: 0px 0px 20px 20px;
    border: 2px dashed #A5D7E5;
    position:absolute;
    margin:auto;
    inset: 0;
    width:90%;
    height:82%;
    pointer-events: none;

}
.pocket .text-xl{
    font-size:1.1rem;
    margin-bottom: .4rem;
}
.pocket span{
    text-decoration: underline;
    display:block;
    font-size:.8rem;
    font-weight:500;
    color: #40A9EF;
}

.folder-paper{
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.18);
    position:absolute;
    width:80%;
    height:80%;
    top: -20%;
    margin: auto;
    right:0;
    left:0;
    transition: .3s;
}
.folder-paper:last-child{
    transform: rotate(8deg) translateX(5px);
}
.folder-group:hover .folder-paper:first-child{
    transform: rotate(-5deg) translateX(-5px) translateY(-2px);
    transition-delay: .1s;
}
.folder-group:hover .folder-paper:last-child{
    transform: rotate(5deg) translateX(2px) translateY(-8px);
}
.folder-group:hover .pocket{
    box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.18);
}

@media only screen and (max-width:1200px) {
    .my-folders-wrapper {
        padding: 0 1rem;
    }
    .folder-group{
        width: calc(100% / 3 - 1.5rem);
    }
}
@media only screen and (max-width:800px) {
    .folders-wrap {
        gap: 1rem;
        row-gap: 3rem;
    }
    .my-folders{
        padding:2rem .5em;
        padding-top:5rem;
    }
    .cloudy{
        left:0;
    }
    .rainbow{
        top: -.5rem;
        left:5rem;
    }
    .light{
        width:45px;
        top:0;
    }
    .pocket .text-xl{
        font-size:1rem;
    }
}

@media only screen and (max-width:650px) {
    .folder-group{
        width: calc(100% / 2 - 1rem);
    }
}


/* floating drawwing */

.float {
    animation: float 10s linear infinite;
}
@keyframes float {
    0%,100%{}
    30%{transform: translate(5px,10px) rotate(4deg);}
    70%{transform: translate(10px,-10px) rotate(-4deg);}
}
.float-delay {
    animation-delay: 5s;
}

.frRabbit {
    width: 6em;
    position: absolute;
    top: 62%;
    left: 5%;
}

.frPen3 {
    width: 6em;
    position: absolute;
    bottom: 50%;
    right: 5%;
}

.frPen4 {
    width: 4em;
    position: absolute;
    top: 50%;
    left: 4%;
}

.frWave {
    width: 6em;
    position: absolute;
    bottom: 17%;
    right: 4%;
}
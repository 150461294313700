.paint_maze_wrap {
    touch-action: none;
    overflow: hidden;
    position: absolute;
    cursor: none;
}

.paint_maze_wrap:focus {
    outline: none;
}

.paint_maze_cell {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paint_maze_riddle {
    font-weight: 600;
    color: black;
}

.paint_maze_answer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paint-bucket {
    position: relative;
    text-align: center;
}

.paint-bucket p {
    position:absolute;
    right:0;
    left:0;
    bottom:.7rem;
    color: black;
    font-weight:600;
}

.painter{
    position:absolute;
    top: 0;
    left:0;
    transform: translate(-25px, -90px);
    pointer-events: none;
}

.painter.clicked{
    scale: .9;
    transition: .10s;
}
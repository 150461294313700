.nf_wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 2em;
    margin-top: 2em;
}

.nf_img {
    max-width: 85%;
}

.nf_text {
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    margin: 0;
    color: white;
    text-align: center;
}
.tl_wrap {
    gap: 1em;
    margin: 0;
    padding: 0em 3em;
    overflow-y: auto;
    height: 100%;
    padding-bottom: 4rem;
}

.tl_wrap_inside {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.tl_main_header {
    font-size: 2.5rem;
    color: #0761A6;    ;
    line-height: normal;
    font-weight: 800;
    margin: 0;
    text-align: center;
}

#tablet.tl_main_header {
    font-size: 2.25rem;
}

#mobile.tl_main_header {
    font-size: 2rem;
}

.tl_sub_header {
    font-size: 1.5rem;
    color: #0761A6;
    line-height: normal;
    font-weight: 800;
    margin: 0;
    text-align: start;
    margin-top: 1em;
}

#tablet.tl_sub_header {
    font-size: 1.25rem;
}

#mobile.tl_sub_header {
    font-size: 1rem;
}

.tl_text {
    font-size: 1.5rem;
    color: #0761A6;
    line-height: normal;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

#tablet.tl_text {
    font-size: 1.25rem;
}

#mobile.tl_text {
    font-size: 1rem;
}

.tl_link {
    font-size: 1.5rem;
    color: #F76E5B;
    text-decoration: underline;
    line-height: normal;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: start;
    background: none;
    border: none;
    cursor: pointer;
}

#tablet.tl_link {
    font-size: 1.25rem;
}

#mobile.tl_link {
    font-size: 1rem;
}

.tl_img_wrap {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.tl_img {
    max-width: 90%;
    border-radius: 20px;
    @media screen and (min-width: 1200px) {
        max-width: 60%;
    }
}

.tl_icons_horiz_wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 1.5em;
    @media screen and (min-width: 1200px) {
        width: 50%;
    }
}

.tl_youtube_wrap {
    display: flex;
    justify-content: center;
    width: 100%;
}


.tl_youtube_container {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    padding-top: 56.25%;
    margin: 1.5em;
    width: 90%;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0.25em;
    }

  }

  .tl_youtube_iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

.turnScreenWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.turnScreenText {
    color: #0761A6;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1em;
    text-align: center;
}



.footer_app_menu_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6rem;
    border-top: 1px soild;
    margin-bottom: 1rem;
    width: 100%;
    flex-wrap: wrap;
    gap: 1em;
}

.footer_single_app_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

.footer_single_app_menu_text {
    font-size: 1rem;
    color: #0761A6;
    font-weight: 500;
    margin: 0;
}
/* floating drawwing */

.float {
    animation: float 10s linear infinite;
}
@keyframes float {
    0%,100%{}
    30%{transform: translate(5px,10px) rotate(4deg);}
    70%{transform: translate(10px,-10px) rotate(-4deg);}
}
.float-delay {
    animation-delay: 5s;
}

.mtRabbit {
    width: 6em;
    position: absolute;
    top: 62%;
    left: 5%;
}

.mtPen {
    width: 3em;
    position: absolute;
    left: 3%;
    top: 33%;
}

.mtPallete {
    width: 6em;
    position: absolute;
    top: 22%;
    left: 4%;
}

.mtPaint {
    width: 3em;
    position: absolute;
    top: 44%;
    right: 7%;
}

.mtEraser {
    width: 3em;
    position: absolute;
    top: 32%;
    right: 3%;
}

.mtWave {
    width: 6em;
    position: absolute;
    bottom: 17%;
    right: 4%;
}
.letter_button {
    position: relative;
    background: none;
    border: none;
}

.letter_btn_txt {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    line-height:1;
    pointer-events: none;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:3em;
    font-weight:800;
    margin:0;
    padding-bottom: 15%;
}

#number.letter_btn_txt {
    font-size:2.5em;
}

#letter.letter_btn_txt {
    padding-bottom: 10%;
}

.classContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    @media (orientation: portrait) {
        height: 100%;
    }
    grid-row: 2;
}

.classContentHoriz {
    display: flex;
    flex-direction: column; /*new*/
    align-items: center;
    gap: 0.25rem; /*New*/
    width: 100%;
    height: 100%;
    position: relative;
}

.classHorizAbsAction {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1em;
}

.horizActions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navBtnTextWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#next_horiz.navBtnTextWrap {
    position: absolute;
    left: 2.5%;
    bottom: 50%;
}

#back_horiz.navBtnTextWrap {
    position: absolute;
    right: 2.5%;
    bottom: 50%;
}

.navText {
    margin: 0;
    color: #0761A6;
    font-weight: 800;
    font-size: 0.75rem;
}

.class_progress_wrap {
    display: flex;
    flex-direction: column;
    direction: ltr;
}

.class_progress_stars{
    display: flex;
    gap: 0.5em;
}

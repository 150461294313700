.slider-recom-wrap{
padding:0 3rem;
}
.slider-recom{
    padding:1.4rem 0 2rem;
}
.slider-recom-item{
    position: relative;
    width:100%;
    background-color: white;
    box-shadow: 0px 4px 22px #F8DCA9;
    border-radius: 12px;
    border: 9px solid white;
    overflow: hidden;
}
.slider-recom-item img{
    max-width: 100%;
}

@media only screen and (max-width:768px) {
    .slider-recom-wrap{
        padding:0;
        margin:0 -20px;
    }
    .slider-recom{
        padding-right:20px;
        padding-left:20%;
        padding-top:.5rem;
    }
    .slider-recom-item{
        border-width:7px;
    }
}
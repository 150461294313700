.floating_back_btn {
    position: fixed;
    right:10px;
    top:10%;
    z-index:4;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: fit-content;
}
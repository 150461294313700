.mainLayoutBackground {
    display: block;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#home_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/home.svg');
}

#home_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/home_mobile.svg');
}

#home_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/home_mobile.svg');
}

#cr_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/classroom.svg');
}

#cr_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/classroom.svg');
}

#cr_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/classroom_small.svg');
}

#class_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/class.svg');
}

#class_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/class.svg');
}

#class_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/class.svg');
    @media (orientation: landscape) {
        background-image: url('../../assets/background/class_small.svg');
    }
}

#promo_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/class.svg');
}

#promo_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/class.svg');
}

#promo_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/class.svg');
    @media (orientation: landscape) {
        background-image: url('../../assets/background/class_small.svg');
    }
}

#folder_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/folder.svg');
}

#folder_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/folder.svg');
}

#folder_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/folder_small.svg');
}

#moretasks_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/more_tasks.svg');
}

#moretasks_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/more_tasks.svg');
}

#moretasks_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/more_tasks_small.svg');
}

#tips_desktop.mainLayoutBackground {
    background-image: url('../../assets/background/tips.svg');
}

#tips_tablet.mainLayoutBackground {
    background-image: url('../../assets/background/tips.svg');
}

#tips_mobile.mainLayoutBackground {
    background-image: url('../../assets/background/tips_small.svg');
}

#notfound.mainLayoutBackground {
    background-image: url('../../assets/background/notfound.svg');
}

#text.mainLayoutBackground {
    background-color: #FAF8ED;
}

/*no margin on header/footer - they are fixed in terms of height - effects the useClass HorizVid Calc*/

.mainLayoutContent {
    display: grid;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 0.5em;
    grid-template-rows: 0.3fr 3fr 0.25fr;
}

#class_tablet.mainLayoutContent {
    gap: 0
}
#class_desktop.mainLayoutContent {
    gap: 0
}
#class_mobile.mainLayoutContent {
    gap: 0
}
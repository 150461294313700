
.word_drag_letter_container {
    touch-action: none;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
}

.word_drag_image_container {
    z-index: 0;
    border-radius: 20px;
    position: absolute;
}

.worddragItemWrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.worddragPlaceHolder {
    position: absolute;
    z-index: 0;
    padding: 0.15rem;
}

.worddragPlaceHolderInner {
    border: 3.5px solid white;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}



.SectionCards {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    display: grid;
    gap: 2rem;
  }
  .hp-card {
    border-radius: 22px;
    background: #fff;
    box-shadow: 0px 5px 15px 0px rgba(197, 182, 155, 0.3);
    overflow: hidden;
    padding: 0;
  }
  .hp-card-img {
    overflow: hidden;
    position: relative;
    padding-top: 45%;
  }
  .hp-card-img img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    inset: 0;
    transition: 0.3s;
  }
  
  .hp-card-img-text {
    position: absolute;
    bottom: 0%;
    right: 40%;
    width: 60%;
    padding: 1rem;
    color: #0761a6;
    font-weight: bold;
    text-align: center;
  }

  .hp-card-img-subtext {
    position: absolute;
    bottom: 0%;
    right: 40%;
    width: 60%;
    padding: 0.5rem;
    color: #0761a6;
    font-weight: 400;
    text-align: center;
  }
  
  .hp-card-bottom {
    padding: 0.5rem;
    text-align: center;
    color: #0761a6;
    font-size: 15px;
  }
  .hp-card-bottom p {
    margin-bottom: 3px;
  }
  .hp-cad-btn {
    color: #f76e5b;
    font-weight: bold;
    text-decoration: underline;
    font-size: 16px;
    margin-bottom: 0.4rem;
  }
  #soon.hp-cad-btn {
    color: #9daaae;
    text-decoration: none;
  }
  
  @media only screen and (min-width: 980px) {
    .hp-card-img:hover img {
      transform: scale(1.05);
    }
    .SectionCards {
      grid-template-columns: repeat(3, 1fr);
    }
    .hp-card-img-text {
      bottom: 15%;
      font-size: 1.2rem;
    }
    .hp-card-img-subtext {
      bottom: 0%;
      font-size: 0.8rem;
    }
  }
  
  @media only screen and (max-width: 1200px) and (min-width: 980px) {
    .hp-card-img-text {
      font-size: 0.85rem;
      padding: 0.5rem;
      bottom: 15%;
    }
    .hp-card-img-subtext {
      font-size: 0.85rem;
      padding: 0.5rem;
      bottom: 0%;
    }
  }
  
  @media only screen and (max-width: 980px) {
    .SectionCards {
      gap: 1.5rem;
      width: 90%;
      grid-template-columns: repeat(2, 1fr);
    }
    .hp-card-img-text {
      bottom: 15%;
      font-size: 1rem;
    }
    .hp-card-img-subtext {
      font-size: 0.9rem;
    }
    .hp-card-bottom {
      padding: 0.5rem 1rem;
    }
  }
  @media only screen and (max-width: 650px) {
    .SectionCards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
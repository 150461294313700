.cb_button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    aspect-ratio: 1/1;
    border-radius: 100%;
    background: none;
    border: none;
    cursor: pointer;
}

.cb_button:active {
  -webkit-tap-highlight-color: transparent;
}

#normal.cb_button {
  animation: btnScale 1.25s infinite;
}


#explode.cb_button {
    animation: btnExplosion 1.25s infinite;
}

.cb_button:disabled {
    opacity: 0.6;
    cursor: auto;
}

.cb_button:hover {
    animation: btnScale .5s forwards;
}

.cb_button:hover:disabled {
    animation: none;
}

@keyframes btnScale {
    0%,100%{}
    50%{transform: scale(.95);}
}

.cb_button_img {
    width: fit-content;
}


@keyframes btnExplosion {
    0% {
      transform: rotate(0deg) scale(1);
      /* background-color: #ffcc00; */
      box-shadow: 0 0 0 rgba(255, 255, 0, 0);
    }
    25% {
      transform: rotate(10deg) scale(1.1);
      /* background-color: #ff9900; */
      box-shadow: 0 0 10px rgba(255, 165, 0, 0.5);
    }
    50% {
      transform: rotate(-10deg) scale(1.2);
      /* background-color: #ff6600; */
      box-shadow: 0 0 20px rgba(255, 0, 0, 0.5);
    }
    75% {
      transform: rotate(10deg) scale(1.1);
      /* background-color: #ff9900; */
      box-shadow: 0 0 10px rgba(255, 165, 0, 0.5);
    }
    100% {
      transform: rotate(0deg) scale(1);
      /* background-color: #ffcc00; */
      box-shadow: 0 0 0 rgba(255, 255, 0, 0);
    }
  }
.maze_wrap {
    touch-action: none;
    overflow: hidden;
    position: relative;
    background-color: #E0DBC0;
    border-radius: 20px;
}

.maze_wrap:focus {
    outline: none;
}

.maze_player {
    position: absolute;
}

@keyframes opacityAnim {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
}

.maze_player.withAnimation {
    animation: 0.35s ease-out opacityAnim;
}

.maze_player.rotateRight {
    transform: rotateY(-180deg);
}

.maze_letter {
    position: absolute;
    display: flex;
    justify-content: center;
}

.maze_cell {
    position: absolute;
    border-radius:5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

#tile1.maze_cell {
    background-image: url('src/assets/images/maze_tile.svg');
}

#block1.maze_cell {
    background-image: url('src/assets/images/maze_block.svg');
}

#tile2.maze_cell {
    background-image: url('src/assets/images/maze_tile2.svg');
}

#block2.maze_cell {
    background-image: url('src/assets/images/maze_block2.svg');
}

.maze_nav_buttons {
    position: absolute;
    top: 57%; /*1 - 3/7 - 7 rows total, 3 are taken */
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.maze_nav_btn_row {
    width: 100%;
    display: flex;
    justify-content: center;
}

#middle_row.maze_nav_btn_row {
    justify-content: space-around;
}
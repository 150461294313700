.mlContent {
    overflow-y: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

@keyframes opacityAnim {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
}

.mlTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 90%;
}

.mlGridWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.mlGridWrapper.withAnimation {
    animation: 2s ease-out opacityAnim;
}

.mlBottomButtons {
    display: flex;
    margin-bottom: 1em;
    margin-top: auto;
    gap: 3rem;
}

#mobile.mlBottomButtons {
    flex-direction: column;
    gap: 1rem;
}

.mlButtonsWrapper {
    display: flex;
}

/*texts*/
.mlMainHeader {
    font-style: normal;
    font-weight: 800;
    font-size: 2.5rem;
    margin: 0;
    color: #0761A6;
    text-align: center;
}

.mlSubHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: #0761A6;
    margin: 0.5em;
    text-align: center;
}

#link.mlSubHeader {
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.mlSubHeaderSmall {
    /* font-style: normal; */
    font-weight: 300;
    font-size: 0.75rem;
    color: #0761A6;
    text-align: center;
    margin: 0.25em;
}

#link.mlSubHeaderSmall {
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}
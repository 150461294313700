.lp-form{
    gap:.6rem;
    text-align: right;
    max-width:950px;
    margin:1rem auto;
    align-items: flex-end;
}

.input-wrap{
    width:27%;
    position: relative;
}

@media only screen and (max-width:1024px) {
    .lp-form{
        max-width:400px;
        padding:0 .5rem;
    }
    .input-wrap{
        width:100%;
        margin-bottom:.2rem;
    }
}
.homeContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin: 1em 0 1em 0;
    overflow-y: auto;
    position: relative;
    height: 100%;
}
/* floating drawwing */

.float {
    animation: float 10s linear infinite;
}
@keyframes float {
    0%,100%{}
    30%{transform: translate(5px,10px) rotate(4deg);}
    70%{transform: translate(10px,-10px) rotate(-4deg);}
}
.float-delay {
    animation-delay: 5s;
}

.homePen {
    width: 4em;
    position: absolute;
    top: 81%;
    left: 6%;
}

.homeBook {
    width: 7em;
    position: absolute;
    top: 85%;
    left: 8%;
}

.homePaint {
    position: absolute;
    top: 75%;
    left: 84%;
}

.homeEraser {
    position: absolute;
    top: 69%;
    left: 89%;
}
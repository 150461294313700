.socials{
    gap: 2.5rem;
    margin:1rem 0;
    flex-wrap: wrap;
}
.socials img{
    transition: .25s;
}
.socials button:hover img{
    transform: translateY(-5px);
}

@media only screen and (max-width:768px) {
    .socials{
        gap: .6rem;
        margin:1.5rem 0;
    }
    .socials img{
        width:48px;
    }
}
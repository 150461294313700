.mliItem {
    background: white;
    border-radius: 20px;
    max-width: 250px;
}

.mliImgWrapper {
    display: flex;
    position: relative;
    max-width: 250px;
    max-height: 140px;
}

.mliBtnWrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.lockImg {
    cursor: pointer;
    width: 52px;
    height: 65px;
}

.mliTimeTextWrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
}

.mliTimeText{
    /* font-style: normal; */
    font-weight: 800;
    color: white;
    font-size: 16px;
    margin: 0;
    margin-left: 0.5em;
}

.mliTimeImg {
    margin-bottom: 0.2em;
    margin-left: 0.5em;
}

.mliImg {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    max-width: 100%;
    filter: brightness(50%);
}

.mliTextWrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 0.5em 0.5em 0;
    text-align: right;
}

.mliTextHeader {
    margin: 0.25em 0.25em 0 0;
    font-weight: 800;
    font-size: 18px;
    color: #0761A6;
    line-height: normal;
}

.mliSubTextWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-right: 0.5rem;
    align-items: center;
}

.mliSubText {
    margin: 0;
    font-size: 14px;
    color: #0761A6;
    line-height: normal;
}

.mliPenIcon {
    margin-left: 0.5rem;
}

.mliTextDotWrap {
    display: flex;
    margin-left: 0.5rem;
}

.mliDot {
    color: #6DBFF6;
    margin: 0;
    line-height: normal;
    font-size: 14px;
    margin-right: 0.5rem;
}

.cash_game_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 0.25em; */

    background-image: url('src/assets/images/cash_game/bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    opacity: 0.8;
}

.cash_registry_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.cash_game_cash_container {
    z-index: 0;
}

.cash_game_amount {
    font-weight: 600;
    color: black;
    position: absolute;
    top: 6%;
    left: 8%;
}

.cash_game_coins {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-items: center;
    margin-top: auto;
    margin-bottom: 1.5em;
}

.cash_game_coin_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cash_game_coin_wrap_actions {
    display: flex;
    width: 70%;
}

.cash_game_coin_action_btn {
    color: #0761A6;
    border: #0761A6 2.5px solid;
    width: 50%;
    background-color: white;
}

.cash_game_coin_img_wrap {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.cash_game_coin_amount {
    font-weight: 800;
    position: absolute;
    color: #946733
}
.folderImgWrapper {
    display: flex;
    position: relative;
}

.folderImageFrame {
    position: relative;
    opacity: 0.9;
    z-index: 1;
    max-width: 100%;
}

.folderImage {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 1;
}
.login_modal_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.login_modal_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    width: 95%;
    margin-bottom: 1.5em;
    direction: ltr;
}

.login_modal_header {
    font-weight: 800;
    font-size: 3rem;
    color: white;
    text-align: center;
}

#tablet.login_modal_header {
    font-size: 2.5rem;
}

#mobile.login_modal_header {
    font-size: 2rem;
}

.login_modal_subtextr {
    font-size: 1.5rem;
    font-weight: 300;
    color: white;
    margin: 0.5em;
    text-align: center;
}

#tablet.login_modal_subtextr {
    font-size: 1.25rem;
}

#mobile.login_modal_subtextr {
    font-size: 1rem;
}

.login_emails_form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: center;
    width: 75%;
}

.forgot_pass_wrap {
    display: flex;
    justify-content: right;
    width: 75%;

}
/*header*/
.header{
    width:100%;
    position: sticky;
    top:0;
    z-index:5;
    box-shadow: 0px 2px 22px rgba(0, 0, 0, 0.15);
}
.header .container{
    height:93px;
}
.menu ul{
    list-style: none;
    gap: 1.3rem;
    margin:0;
    padding:0;
}
.app_menu_btn{
width: 35px;
height:22px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-end;
}
.app_menu_btn span{
display:block;
width:100%;
height:4px;
background: var(--blue-dark);
border-radius: 10px;
transition: .25s;
}
.app_menu_btn span:nth-child(2){
width: 60%;
}
.backtop{
    position: fixed;
    bottom:40px;
    right:30px;
    background-color: white;
    z-index:4;
    width:55px;
    border-radius:50%;
    aspect-ratio: 1;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    transition: .3s;
    pointer-events: none;
    opacity: 0;
    width:55px;
    height:55px;
}
.backtop.show{
    opacity: 1;
    pointer-events: all;
}


@media only screen and (max-width:1024px) {
    .menu{
        position: fixed;
        top:0;
        left:0;
        height:100svh;
        width:100%;
        max-width:400px;
        background-color:var(--blue);
        clip-path: circle(0% at 9% 5%);
        box-shadow: 0 2px 32px 0 rgba(0,0,0,.3);
        pointer-events: none;
        transition: .4s;
        overflow: auto;
        padding:25px;
    }
    .menu[aria-expanded="true"]{
        clip-path: circle(150% at 6% 5%);
        pointer-events: all;
    }
    .menu ul{
        display:block;
        padding-top:30px;
    }
    .menu ul a{
        font-size:1.3rem;
        padding:10px 0;
        display:block;
        color: white;
    }
    .app_menu_close{
        position:absolute;
        left:22px;
        top:25px;
        padding:.3rem;
    }
}

@media only screen and (max-width:768px) {
   .logo img{
    height:45px;
   }
   .header .container{
    height:70px;
    }
    .backtop{
        width:48px;
        height: 48px;
        right:15px;
        bottom:22px;
    }
    .backtop img{
        width:20px;
    }
}
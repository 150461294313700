.hero{
    background: linear-gradient(0deg, rgba(26, 147, 216, 0.2), rgba(26, 147, 216, 0.2)), 
                linear-gradient(180deg, #3EA8EE 0%, #82CAFB 100%);
}
.hero:before{
    content:"";
    display:block;
    width:100%;
    height:160px;
    position:absolute;
    bottom:-1px;
    right: 0;
    background: url('../../assets/images/Hills.svg') repeat-x center;
    z-index:2;
}
.hero-content{
    padding:5rem 0 12rem;
}
.ul-ok{
    list-style: none;
    padding:0;
}
.ul-ok li{
    position: relative;
    padding-right:1.4rem;
    margin-bottom:.4rem;
}

.ol-ok{
    margin-right:1.4rem;
    padding:0;
}


.ol-ok li {
    position: relative;
    margin-bottom:.4rem;
}


.ul-ok li:before{
    content:"";
    display:block;
    width:16px;
    height:16px;
    position:absolute;
    top:.3rem;
    right: 0;
    background: url('../../assets/images/ok-white.svg') no-repeat center;
}
.hero-hold-img{
    position:absolute;
    bottom:0;
    left:1%;
}
.hero-img-wrap:before{
    content:"";
    display:block;
    border: 2px dashed #C6E2E8;
    border-radius:100%;
    width:440px;
    height: 440px;
    aspect-ratio: 1;
    position:absolute;
    top: -12%;
    left: -7%;
    animation: rotateCircle 20s linear infinite;
}
@keyframes rotateCircle {
    0%{}
    100%{transform: rotate(360deg);}
}
.hero-icon{
    position:absolute;
    top:15%;
    left:-16%;
    filter:drop-shadow(0 2px 10px rgba(0,0,0,.12))
}
.hero-icon:nth-child(2){
    top: -14%;
    left: 20%;
}
.hero-icon:nth-child(3){
    top: -9%;
    left: 105%;
}
.hero-icon:nth-child(4){
    top: 24%;
    left: 125%;
}

.hero-l{
    position:absolute;
    left:2%;
    bottom:30%;
    max-width:12%;
}
.hero-r{
    position:absolute;
    right:2%;
    top:10%;
    max-width:9%;
}

.hero-boy{
    width:300px;
    border-radius: 20px;
    left: 49px;
    top: 13px;
}

@media only screen and (min-width:768px) {
    .hero-content{
        width:55%;
    }
}
@media only screen and (max-width:1200px) {
    .hero-content{
        width:60%;
    }
}
@media only screen and (max-width:1024px) {
    .hero-content{
        width:100%;
        padding:2.4rem 0 5rem;
    }
    .hero-hold-img{
        position: static;
        width:100%;
    }
    .hero-img-wrap{
        display:inline-block;
    }
    .hero:before{
        background-size:auto 100%;
        height:110px;
    }
}
@media only screen and (max-width:650px) {
    .hero-content .title-42 br{
        display: none;
    }
    .hero-img-wrap:before{
        width:300px;
        height: 300px;
    }
    .hero-icon{
        width:52px;
    }
    .hero-icon:nth-child(4){
        left:114%;
    }
    .hero-icon:nth-child(3){
        top: -7%;
        left: 100%;
    }
    .hero-boy{
        width:224px;
        left: 10%;
        top: 13px;
    }
    .hero-l{
        max-width:24%;
        bottom:35%;
    }
}
.textBtn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 16px;
    border-radius: 0.5em;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    line-height: 1;
    font-weight: 800;
}

.textBtn:disabled {
    opacity: 0.3;
    cursor: auto;
}
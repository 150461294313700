.mainLayoutHeader {
    display: flex;
    justify-content: space-between;
    width: 95%;
    grid-row: 1;
    margin:auto;
    align-items: center;
}

#in_course.mainLayoutHeader {
    border-bottom: 2px soild #BEEDFB;
}

.mainLayoutHeaderSide {
    display: flex;
    align-items: center;
    gap: 1em;
}
.mainLayoutHeaderSide.right {
    gap: 0.5em
}

.buttonsPannel {
    display: flex;
    gap: 2em;
}

.buttonWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.1em;
}

.buttonWrap > p {
    font-size: 12px;
    font-weight: 500;
}

.logoBtn {
    background: none;
    border: none;
    cursor: pointer;
}

#main.logoBtn {
    width: 9rem;
    @media screen and (min-width: 1200px) {
        width: 12rem;
    }
}

#in_course.logoBtn {
    width: 42px;
    height: 42px;
}
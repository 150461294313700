
.sec-night{
    background-image: linear-gradient(180deg, #181546 0%, #0761A6 100%);
    padding-top: 8rem;
    padding-bottom:5rem;
}
.sec-night:before{
    content: "";
    display: block;
    width:100%;
    position: absolute;
    top:40px;
    left:0;
    bottom:0;
    background: url('../../assets/images/stars.png') repeat-x center top;
    background-size: 100% auto;
}
.all-ships{
    gap:.5rem;
    margin-bottom: 1rem;
}
.all-ships img{
    animation: ship 1.8s ease-in-out infinite;
}

.ship_svg{
    width: 90px;
    height: 90px;
}
@keyframes ship {
    0%,50%,100%{
        transform: translateY(0);
    }
    20%{
        transform: translateY(-8px) rotate(-5deg);
    }
}
.all-ships img:nth-child(2){
    animation-delay: calc(1 * .25s);
    position: relative;
    top: -1rem;
}
.all-ships img:nth-child(1){
    animation-delay: calc(2 * .25s);
}
.night-icons{
    padding:2rem;
    gap: 1rem;
}
.night-icons img{
    margin-bottom:.4rem;
    transition: .25s;
}
.night-icons > div:hover img{
    transform: translateY(-5px);
}
.night-icons .text-base{
    max-width: 215px;
}

@media only screen and (min-width:768px) {
    .sec-night .title-42{
        max-width:600px;
    }
}
@media only screen and (max-width:768px) {
    .night-icons{
        padding:2rem 0;
        row-gap: 2rem;
    }
    .sec-night{
        padding-top:4rem;
    }
    .sec-night:before{
        top:10px;
        background-size:200% auto;
    }
    .all-ships img{
        width:65px;
    }
}

@media only screen and (max-width:650px) {
    .sec-night .title-42{
        font-size:8vw;
    }
    .sec-night .text-xl{
        font-size:5.2vw;
    }
    .night-icons img{
        width:65px;
    }
}

.landing_float_wizard {
    width: 14em;
    position: absolute;
    bottom: 12%;
    right: 6%;
}

.landing_float_billy {
    width: 12em;
    position: absolute;
    bottom: 12%;
    right: 4%;
}

.landing_float_left {
    width: 10em;
    position: absolute;
    bottom: 5%;
    left: 8%;
}
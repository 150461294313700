.appRightsText {
    color: #8D8C9A;
    font-size: 0.85rem;
    font-weight: 400;
    margin: 1em 0 1em 0;
    text-align: center;
}

#link.appRightsText {
    cursor: pointer;
    background: none;
    border: none;
    margin: 0;
    text-decoration-line: underline;
    padding: 0;
    font-weight: 400;
}

.appRightsWrapper {
    display: flex;
    width: 100%;
    font-size: 0.75rem;
    justify-content: center;
    font-weight: 400;
    font-style: normal;
    margin-top: auto;
    grid-row: 3;
}
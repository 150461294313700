.fim_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    margin: 0.25em;
    padding: 0.25em;
    max-width: 450px;
}

.fim_desc {
    margin: 0;
    align-self: center;
    font-weight: 800;
    font-size: 1.5rem;
    color: #0761A6;
}

.fim_btns_wrapper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
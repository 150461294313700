.address_content{
    width: 100%;
}


.address_modal_bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 11em;
}

.address_modal_header {
    font-weight: 800;
    font-size: 3rem;
    color: white;
    text-align: center;
}

#tablet.address_modal_header {
    font-size: 2.5rem;
}

#mobile.address_modal_header {
    font-size: 2rem;
}

.address_modal_subtextr {
    font-size: 1.5rem;
    font-weight: 300;
    color: white;
    margin: 0.5em;
    text-align: center;
}

#tablet.address_modal_subtextr {
    font-size: 1.25rem;
}

#mobile.address_modal_subtextr {
    font-size: 1rem;
}

.address_modal_note {
    font-size: 1.25rem;
    font-weight: 300;
    color: gray;
    margin: 0.5em;
    text-align: center;
}

#tablet.address_modal_note {
    font-size: 1rem;
}

#mobile.address_modal_note {
    font-size: 0.75rem;
}

.address_modal_form_row {
    display: flex;
    gap: 2em;
    margin: 1em;
}
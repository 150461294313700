.payment_modal_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 450px;
    overflow: hidden;
    width: 100%;
}

.payment_iframe {
    width: 100%;
    height: 100%;
    min-height: 470px;
}

.payment_modal_header {
    font-weight: 800;
    font-size: 3rem;
    color: white;
}

#tablet.payment_modal_header {
    font-size: 2.5rem;
}

#mobile.payment_modal_header {
    font-size: 2rem;
}

.payment_modal_subtextr {
    font-size: 1.5rem;
    font-weight: 700;
    color: white
}

#tablet.payment_modal_subtextr {
    font-size: 1.25rem;
}

#mobile.payment_modal_subtextr {
    font-size: 1rem;
}

.payment_modal_amount {
    font-weight: 800;
    font-size: 2.5rem;
    color: white;
}

#tablet.payment_modal_amount {
    font-size: 2rem;
}

#mobile.payment_modal_amount {
    font-size: 1.5rem;
}

.payment_emails_form {
    display: flex;
    flex-direction: column;
    margin: 0.5em;
    text-align: center;
    direction: ltr;
    gap: 1em;
}

.payment_action_bottom {
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
}

.payment_form_wrapper {
    display: flex;
    flex-direction: column;
}

.bottom_msg {
    margin-top: 1em;
    margin-bottom: 2em;
    color: black;
}
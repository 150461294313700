.btn-mic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    aspect-ratio: 1/1;
    border-radius: 100%;
    border: none;
    cursor: pointer;
    background: #F76E5B;
    box-shadow: 1px 2px 0px 3px #ED4D37;
}

.btn-mic:disabled {
  opacity: 0.3;
  cursor: auto;
}

.btn-mic:hover:disabled .micImg {
    animation: none;
}

.btn-mic:hover .micImg {
    animation: moveMic 1s infinite;
}
@keyframes moveMic {
    0%,100%{}
    50%{transform: rotate(-15deg) scale(.9);}
}

.micGif {
    mix-blend-mode: darken;
    width: 100%;
    height: 100%;
}
.title-42{
    font-size: clamp(1.7rem, 6vw, 2.4rem);
    font-weight: bold;
    line-height: 1.3;
    margin-bottom:.6rem;
}
.text-base{
    font-size: 1rem;
    line-height: 1.4;
}
.text-xl{
    font-size: 1.25rem;
    line-height: 1.4;
}
.text-xxl{
    font-size: 1.5rem;
    line-height: 1.4;
}
.font-medium{
   font-weight:500;
}
.font-bold{
    font-weight: bold;
}
li, p{
    font-size: 18px;
}
@media only screen and (max-width:650px) {
    .text-xl{
        font-size: 1.2rem;
    }
}
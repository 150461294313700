body {
  margin: 0;
  font-family: 'Heebo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* height: 100vh; */
  /* width: 100vw; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  margin: 0;
  padding:0;
  /* overflow: hidden; */
}

h1,h2,h3,p,h6{
  margin: 0;
  padding: 0;
  font-family: Heebo;
}

button {
  font-family: Heebo;
}
.redirectWrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    background-color: white;
    flex-direction: column;
    justify-content: center;
}

.redirectText {
    font-size: 1.75rem;
    font-weight: 800;
    color: black;
    text-align: center;
}

.baloon{
    top: 0%;
    right:20%;
}
.pen{
    bottom: 4%;
    left:20%;
}

.iphone{
    width:150px;
    left: -15%;
    top: 27%;
}

.course-gif {
    width: 85%;
    margin: 1em;
    border-radius: 20px;
}

.course_ins_wrap {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
    border-bottom: 1px solid gray;
}


@media only screen and (max-width:768px) {
    .sec-best .grade-img-wrap{
        margin-left:0;
    }
}
@media only screen and (max-width:650px) {
    .baloon{
        width:70px;
        display: none;

    }
    .pen{
        width:58px;
        display: none;

    }
    .iphone{
        width:100px;
        left:-12%;
        display: none;

    }
}

.top_cards_wrapper{
    max-width:780px;
    width: 100%;
    margin:0 auto;
    padding:1rem 0;
    display:grid;
    gap:0.5rem;
}
.puzzle_wrap {
    touch-action: none;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../assets/background/ship_bg.svg');
    position: relative;
    border-radius: 20px;
}

.puzzleItemWrap {
    position: absolute;
}

.puzzleItemTextWrap {
    position: absolute;
    top: 0;
    left: 0;
}

.puzzlePlaceWrap {
    position: absolute;
}

.puzzlePlaceHolderSpace {
    position: absolute;
    background: #1A7FCE;
    opacity: 0.24;
    border-radius: 20px;
}



.HeaderAvatarWrap {
    display: flex;
    align-items: center;
    gap: 1em;
}

.HeaderAvatarTextsWrap {
    display: flex;
    flex-direction: column;
}

.HeaderAvatarNameText {
    font-size: 1em;
    font-weight: 400;
    line-height: 1;
    inline-size: 120px;
}

.UserMenuWrap {
    display: flex;
    flex-direction: column;
    padding: 1rem;;
    gap: 0.5em;
}

.UserMenuClassWrap {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.UserMenuSubHeader {
    color: #0761A6;
    font-weight: 700;
    font-size: 1.25rem;
}

.UserMenuClassHeader {
    color: #0761A6;
    font-weight: 500;
    font-size: 1.25rem;
    align-self: center;
}

.UserMenuItem {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    line-height: 2;
    border-bottom: 1px solid #BEEDFB
}

#click.UserMenuItem {
    cursor: pointer;
}

.UserMenuItemHeader {
    color: #0761A6;
    font-weight: 400;
    font-size: 1.1rem;
}

.UserMenuItemText {
    color: #626D76;
    font-weight: 400;
    font-size: 1.1rem;
}

.UserMenuIcon {
    width: 1.2rem;
    height: 1.2rem;
}

.UserProfilePurchaseBtn {
    border: none;
    padding: 0;
    cursor: pointer;
    background: none;
    color: #F76E5B;
    font-weight: 700;
    font-size: 1rem;
    text-decoration: underline;
}

#soon.UserProfilePurchaseBtn {
    color: gray;
    cursor: 'auto'
}

.UserProfilePurchaseClass {
    justify-content: space-between;
    display: flex;
    background-color: #E5F7FC; /* Default background color */
    border: 1px solid rgba(0, 0, 0, 0.12); /* Default border */
    border-radius: 4px; /* Default border radius */
    box-shadow: none; /* Remove shadow for a flat look */
    padding: 0.5em;
    gap: 1em;
}

.UserProfilePurchaseClassRight {
    display: flex;
}